import React from "react";
// import heroImage from "../../../images/Untitled-2.png";
import bgImg from "../../../images/BackGrounds/WordsBg.png";
import { Box } from "@mui/system";
import { Container, Grid, Typography, Stack, Button } from "@mui/material";
import { useHistory } from "react-router-dom";
import LoginIcon from "@mui/icons-material/Login";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";

const SmallPage = () => {
  const router = useHistory();

  return (
    <Box
      sx={{
        backgroundImage: `url("${bgImg}")`,
        backgroundSize: "cover", // Ensures the image covers the entire area
        backgroundPosition: "center", // Centers the image
        backgroundRepeat: "no-repeat", // Prevents repeating the image
        height: "70vh", // Set height to 100vh to cover the full viewport height
        width: "100%", // Ensures the background image covers full width
      }}>
      <Container maxWidth="xl">
        <Grid
          container
          sx={{
            display: "flex",
            height: "60vh",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <Grid item xs={12} md={5}>
            <Typography
              sx={{
                fontWeight: "bold",
              }}
              variant="h4">
              Connect, Track, Showcase
            </Typography>

            <Typography
              sx={{
                fontWeight: "bold",
              }}
              variant="h4">
              Community
            </Typography>
            <Typography
              sx={{
                fontWeight: "bold",
              }}
              variant="h4"
              color="primary">
              Hours
            </Typography>
            <Typography sx={{ my: "1rem" }} variant="body2">
              Transform your community service journey with our innovative
              platform. Effortlessly find, schedule, and verify volunteer
              opportunities, track your progress, and build a digital social CV.
              Unite with charities, fulfill school requirements, and make a
              tangible difference. Start your path to social impact today!
            </Typography>
            <Stack direction="row" spacing={2}>
              <Button
                onClick={() => {
                  router.push("/login");
                }}
                variant="contained"
                endIcon={<LoginIcon />}>
                Login
              </Button>
              <Button
                onClick={() => {
                  router.push("/register");
                }}
                variant="outlined"
                color="secondary"
                endIcon={<AppRegistrationIcon />}>
                Register
              </Button>
              {/* <Button
                onClick={handleInstallClick}
                variant="outlined"
                color="secondary"
                endIcon={<AppRegistrationIcon />}>
                Install App
              </Button> */}
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default SmallPage;
