import gradient from "../../images/BackGrounds/WordsBg.png";
import footer from "../../images/BackGrounds/BackGround.png";
import footer2 from "../../images/BackGrounds/astro.jpg";
// import paper from "../../images/BackGrounds/paper.png";
import phoneHandLarge from "../../images/phoneHandmid.png";

import peepsMid from "../../images/peepsMidpng.png";
import peepsSmall from "../../images/peepsSmall.png";
// import phoneChalk from "../../images/phoneChalk.png";
import logo from "../../images/logos/NewLogoLight.png";
import logo2 from "../../images/logos/NewLogoDark.png";
import React from "react";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import { useHistory } from "react-router-dom";
import { ParallaxProvider } from "react-scroll-parallax";
import { Parallax } from "react-scroll-parallax";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import LoginIcon from "@mui/icons-material/Login";
import PersonIcon from "@mui/icons-material/Person";
import FamilyRestroomIcon from "@mui/icons-material/FamilyRestroom";
import HandshakeIcon from "@mui/icons-material/Handshake";
import BusinessIcon from "@mui/icons-material/Business";

import { AppBar, CssBaseline, Grid, IconButton, Toolbar } from "@mui/material";

import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import LargePage from "./Components/LargePage";
import SmallPage from "./Components/SmallPage";

const Homepage = () => {
  const router = useHistory();
  const theme = useTheme();

  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  console.log(matches);

  return (
    <>
      <CssBaseline />
      <ParallaxProvider>
        <AppBar position="static" color="inherit">
          <Container maxWidth="xl">
            <Toolbar>
              <IconButton
                onClick={() => router.push("/")}
                size="large"
                edge="start"
                sx={{ p: 0, mr: 1 }}
                aria-label="open drawer">
                <img
                  src={theme.palette.mode === "dark" ? logo2 : logo}
                  height="35"
                  alt="Logo"
                />
              </IconButton>
              <Typography
                variant="h6"
                noWrap
                component="div"
                sx={{ flexGrow: 1 }}>
                Chalk It Up
              </Typography>
              <Stack direction="row" spacing={2}>
                <Button
                  onClick={() => {
                    router.push("/login");
                  }}
                  variant="contained"
                  endIcon={<LoginIcon />}>
                  Login
                </Button>
                <Button
                  onClick={() => {
                    router.push("/register");
                  }}
                  variant="outlined"
                  color="secondary"
                  endIcon={<AppRegistrationIcon />}>
                  Register
                </Button>
              </Stack>
            </Toolbar>
          </Container>
        </AppBar>
        {matches ? <LargePage /> : <SmallPage />}

        <Container
          display="flex"
          maxWidth="xl"
          sx={{
            bgcolor: "background.default",
            pb: 6,
            backgroundImage: `url(${gradient})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundAttachment: "fixed",
            backgroundPosition: "top",
          }}>
          <Typography
            sx={{
              py: 7,

              textAlign: "center",
              fontWeight: "bold",
              backgroundImage: "linear-gradient(to left, #09ED89, #0FA3FD )",
              backgroundClip: "text",
              color: "transparent",
            }}
            variant="h4"
            align="center">
            MAKE A DIFFERENCE!
          </Typography>
          <Container maxWidth="xl">
            <Grid
              spacing={4}
              container
              justifyContent={"center"}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}>
              <Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
                <Parallax speed={-5}>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                    }}
                    variant="h6">
                    Why Chalk it Up?
                  </Typography>
                  <Typography sx={{ my: "2rem" }} variant="body1">
                    The world is changing at a rapid rate with most industries
                    embracing digitalisation. While traditional community
                    service is still seen as physical time and effort spend at
                    charitable organisations, the process of schedule and
                    recording these hours can be greatly improved with simple
                    digital innovation.
                  </Typography>
                  <Typography sx={{ my: "2rem" }} variant="body1">
                    Many schools, especially in the more affluent communities,
                    require students to volunteer a minimum number of hours at
                    local charities as part of the LO curriculum. The intention
                    is to expose these students to various categories of service
                    and to encourage ongoing community engagement beyond that
                    which is required by the school.
                  </Typography>
                  <Typography sx={{ my: "2rem" }} variant="body1">
                    Chalk it Up has created a digital solution to provide
                    students and their parents, as well as any interested
                    charities, with the ability to effortlessly manage and
                    monitor the availability and completion of community service
                    activities, during their time at school and university.
                  </Typography>
                </Parallax>
              </Grid>
            </Grid>
          </Container>
        </Container>
        <Container maxWidth="xl">
          <Grid
            container
            sx={{
              py: 7,
              display: "flex",
              justifyContent: "space-evenly",
              alignContent: "center",
            }}>
            <Grid item xs={12} md={6} lg={3}>
              <Stack justifyContent="center" alignItems="center">
                <PersonIcon
                  sx={{
                    fontSize: 50,
                    textAlign: "center",
                    color: "secondary.main",
                  }}
                />

                <Typography variant="h6">Students</Typography>
                <Typography
                  variant="body1"
                  align="center"
                  sx={{ my: "2rem" }}
                  color="text.secondary">
                  Unlock easy scheduling and digital tracking of community
                  service hours with Chalk it Up. Explore causes, contribute to
                  your community, and build a social CV that stands out. It's
                  community service made simple, meaningful, and accessible.
                </Typography>
              </Stack>
            </Grid>

            <Grid item xs={12} md={6} lg={3}>
              <Stack justifyContent="center" alignItems="center">
                <FamilyRestroomIcon
                  sx={{
                    fontSize: 50,
                    color: "secondary.main",
                    backgroundImage:
                      "linear-gradient(to left, #09ED89, #0FA3FD )",
                    backgroundClip: "text",
                  }}
                />
                <Typography variant="h6">Parents</Typography>
                <Typography
                  sx={{ my: "2rem" }}
                  variant="body1"
                  align="center"
                  color="text.secondary">
                  Ensure your child's community service is productive and safe.
                  Chalk it Up offers a streamlined way to manage schedules,
                  verify charitable engagements, and foster their sense of
                  responsibility, all in one app.
                </Typography>
              </Stack>
            </Grid>
          </Grid>

          <Grid
            container
            sx={{
              pb: 7,
              display: "flex",
              justifyContent: "space-evenly",
              alignContent: "center",
            }}>
            <Grid item xs={12} md={6} lg={3}>
              <Stack justifyContent="center" alignItems="center">
                <HandshakeIcon
                  sx={{
                    fontSize: 50,
                    color: "secondary.main",
                    backgroundClip: "text",
                  }}
                />
                <Typography variant="h6">Charities</Typography>
                <Typography
                  variant="body1"
                  sx={{ my: "2rem" }}
                  align="center"
                  color="text.secondary">
                  Expand your reach and simplify volunteer management with Chalk
                  it Up. This free platform offers digital scheduling, feedback,
                  and a showcase for your cause, reducing administrative effort
                  and enhancing volunteer engagement.
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <Stack justifyContent="center" alignItems="center">
                <BusinessIcon
                  sx={{
                    fontSize: 50,
                    color: "secondary.main",

                    backgroundClip: "text",
                  }}
                />
                <Typography variant="h6">Schools</Typography>
                <Typography
                  variant="body1"
                  align="center"
                  sx={{ my: "2rem" }}
                  color="text.secondary">
                  Chalk it Up provides schools with a digital tool to
                  effortlessly track and authenticate student community service
                  hours. Reduce paperwork, support educational goals, and
                  celebrate your students' contributions to the community
                  seamlessly.
                </Typography>
              </Stack>
            </Grid>
          </Grid>
        </Container>

        <Container display="flex" maxWidth="xl">
          <Typography
            sx={{
              mb: 15,
              mt: 5,
              textAlign: "center",
              fontWeight: "bold",
              backgroundImage: "linear-gradient(to left, #09ED89, #0FA3FD )",
              backgroundClip: "text",
              color: "transparent",
            }}
            variant="h6"
            align="center">
            DESIGNED FOR YOUR COMMUNITY
          </Typography>
          <Container maxWidth="xl">
            <Grid
              spacing={4}
              container
              display="flex"
              direction={matches ? "row-reverse" : "row"}>
              <Grid item xl={5} lg={6} md={12} sm={12} xs={12}>
                <Stack>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                    }}
                    variant="h6"
                    color="initial">
                    Create a digital social CV for future opportunities.
                  </Typography>
                  <Typography sx={{ my: "2rem" }} variant="body1">
                    Show you are different by making a difference. Record skills
                    and gain experience to boost scholarships applications or
                    future career opportunities.
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xl={12} lg={12} md={12} sm={0} xs={0}>
                <Box display="flex" alignItems="flex-end">
                  <img
                    src={!matches ? peepsSmall : peepsMid}
                    alt="Chalk Phone"
                  />
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Container>
        <Container
          maxWidth="xl"
          sx={{
            bgcolor: "background.default",
            pb: 6,
            backgroundImage: `url(${gradient})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundAttachment: "fixed",
            backgroundPosition: "top",
          }}>
          <Typography
            sx={{
              mb: 5,
              mt: 5,
              textAlign: "center",
              fontWeight: "bold",
              backgroundImage: "linear-gradient(to left, #09ED89, #0FA3FD )",
              backgroundClip: "text",
              color: "transparent",
            }}
            variant="h6"
            align="center">
            DESIGNED FOR YOUR COMMUNITY
          </Typography>
          <Container maxWidth="xl">
            <Grid
              spacing={4}
              container
              display="flex"
              direction={!matches ? "row-reverse" : "row"}>
              <Grid
                display="flex"
                alignItems="center"
                item
                xl={5}
                lg={6}
                md={12}
                sm={12}
                xs={12}>
                <Stack sx={{ my: 5 }}>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                    }}
                    variant="h6">
                    Chalk up favour with your LO teacher
                  </Typography>
                  <Typography sx={{ my: "2rem" }} variant="body1">
                    No missing hours or dogs eating forms (dogs only eat
                    homework!). Real-time digital verification by charity
                    through QR codes or OTPs.
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                <Parallax speed={-5}>
                  <Box display="flex" alignItems="center">
                    <img height="300" src={phoneHandLarge} alt="Chalk Phone" />
                  </Box>
                </Parallax>
              </Grid>
            </Grid>
          </Container>
        </Container>

        <Container maxWidth="xl" display="flex">
          <Typography
            sx={{
              mb: 5,
              mt: 5,
              textAlign: "center",
              fontWeight: "bold",
              backgroundImage: "linear-gradient(to left, #09ED89, #0FA3FD )",
              backgroundClip: "text",
              color: "transparent",
            }}
            variant="h6"
            align="center">
            DESIGNED FOR YOUR COMMUNITY
          </Typography>
          <Container maxWidth="xl">
            <Grid
              spacing={4}
              container
              display="flex"
              direction={matches ? "row-reverse" : "row"}>
              <Grid item xl={5} lg={6} md={12} sm={12} xs={12}>
                <Stack>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                    }}
                    variant="h6">
                    Volunteer hours for the school's requirement
                  </Typography>
                  <Typography sx={{ my: "2rem" }} variant="body1">
                    Allows schools to easily verify that the volunteer hours
                    reported by their students are accurate and compliant with
                    school requirements.
                  </Typography>
                </Stack>
              </Grid>
              {/* <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                <Box display="flex" alignItems="flex-end">
                  <img src={SchoolBuilding} alt="School Building" />
                </Box>
              </Grid> */}
            </Grid>
          </Container>
        </Container>

        <Grid
          container
          sx={{
            backgroundImage: `url(${
              theme.palette.mode === "dark" ? footer2 : footer
            })`,
            boxShadow: `inset 0 0 0 2000px ${
              theme.palette.mode !== "dark" ? "#F8F8F8ee" : "#1a1a1a9e"
            } `,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "bottom",
            height: "100vh",
            pt: 20,
            pb: 6,
          }}
          display="flex"
          alignItems="center"
          justifyContent="center">
          <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
            <Typography
              sx={{
                fontWeight: "bold",
                mb: 2,
              }}
              variant="h4"
              align="center"
              component="div">
              Take it up a level.
            </Typography>{" "}
          </Grid>
          <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
            {" "}
            <Typography
              align="center"
              sx={{ fontWeight: "normal" }}
              variant={matches ? "h5" : "body1"}>
              Are you passionate about leading change in your community? Grow
              you influence and leadership skills. Apply to become a Chalk It Up
              Ambassador.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
            <Box justifyContent="center" display="flex" pb={20} pt={10}>
              <Button color="primary" size="large" variant="contained">
                <Typography variant="h4"> get an invite 🤳</Typography>
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
            <Stack direction="column">
              <a href="mailto:hello@chalkitup.co.za">
                <Typography
                  variant="subtitle1"
                  gutterBottom
                  align="center"
                  color="text.secondary"
                  component="p">
                  hello@chalkitup.co.za
                </Typography>
              </a>

              <Copyright />
            </Stack>
          </Grid>
        </Grid>
      </ParallaxProvider>
      {/* Footer */}
    </>
  );
};
function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://material-ui.com/">
        chalk it up
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
      {/* <GifTooltip title="Cat" staticImageSrc={gifImageSrc} /> */}
    </Typography>
  );
}

export default Homepage;

// function GifTooltip({ text }) {

//   const [isGifVisible, setIsGifVisible] = React.useState(false);

//   const handleMouseEnter = () => {
//     setIsGifVisible(true);
//   };

//   const handleMouseLeave = () => {
//     setIsGifVisible(false);
//   };

//   return (
//     <>
//       <Tooltip
//         // remove the background of the tooltip
//         componentsProps={{
//           tooltip: {
//             sx: {
//               bgcolor: "transparent",
//               "& .MuiTooltip-arrow": {
//                 color: "transparent",
//               },
//             },
//           },
//         }}
//         followCursor
//         title={
//           <>
//             {isGifVisible && (
//               <img
//                 src={require("../../images/Random/cat.gif")}
//                 height="100"
//                 alt="Gify boy"

//               />
//             )}
//           </>
//         }
//         placement="top">
//         <span onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
//           {text}
//         </span>
//       </Tooltip>
//     </>
//   );
// }
